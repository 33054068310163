.banner{
    color:white;
    object-fit: contain;
    height: 450px;
}
.banner_contents{
    margin-left: 30px;
    padding-top: 140px;
    height: 190px;
}
.banner_contents_pop{
    /*margin-top: 20vh;*/

    padding-top: 0px;
    height: 45vh;
    width: 100%;
    background-color: rgba(129, 129, 129, 0.37);
}
.banner_title{
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 0.3rem;
}

.banner_description{
    width: 45rem;
    line-height: 1.3;
    padding-top: 1rem;
    font-size: 0.8rem;
    max-width: 370px;
    height: 80px;
}

.banner_button{
    cursor:pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: rgba(51,51,51, 0.5);   
}
.banner_button:hover{
    color: black;
    background-color: white;
    transition: all 0.2s;
}
.banner--fadeBottom{
    position: relative;
    height: 7.5rem;
    background-image: linear-gradient(
    180deg,
    transparent,
    rgba(37,37,37,0.60),
    #112
    );
}