.row{
    width: 97%;
    margin-left: 20px;
    color: white;
}

.row_posters{
    position: relative;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px;
}

.row_posters::-webkit-scrollbar{
    display: none;
}

.arrow_btn{
    border-radius: 5px;
    width: 30px;
    margin:0 0;
    margin-bottom: 15px;
    background-color: #112;
    color: #818181;
    /*border:none;*/
    height: 4vh;
    position: relative;
    top: 14px;
}

.arrow_btn_large{
    border-radius: 5px;
    width: 30px;
    margin:0 5px;
    background-color: #112;
    color: #818181;
    /*border:none;*/
    height: 4vh;
    position: relative;
}

.arrow_btn:hover,.arrow_btn_large:hover{
    background-color: rgba(12, 1, 10, 0.62);
}

.arrow_right{
    right:-6px;
}

.arrow_left{
    left:-6px;
}

.row_movie{
    cursor: pointer;
    object-fit: contain;
    width: 100%;
    max-height: 150px;
    margin-right: 10px;
    transition: transform 450ms;
}

.row_movie:hover{
    transform: scale(1.08);
}

.row_large{
    max-height: 300px;
}
.row_large:hover{
    transform: scale(1.09);
}
.movie_description{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #1c0525;
    height:100%;
    width: 300px;
    border-radius: 20px;
    box-shadow: 0 0 .2rem rgb(216, 213, 213),
    0 0 .4rem rgb(187, 187, 187),
    0 0 .3rem #5e0f7e,
    0 0 0.3rem #5e0f7e,
    0 0 0.2rem #5e0f7e,
    inset 0 0 1rem #1c0525; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.movie_description h1{
    font-size: 11pt;
    margin-left: 20px;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.movie_description p{
    font-size: 10pt;
    width: 95%;
    height: 80%;
    max-height: 90px;
    overflow: hidden;
    position: relative;
    display: block;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
}
.movie_description_buttons{
    /*display: flex;*/
    /*position:relative;*/
    /*bottom: 0;*/
    /*right: 0;*/
    /*padding: 0;*/
    /*justify-content: space-between;*/
    /*margin: 5px;*/
    /*width:12vw;*/
    /*height: 20px;*/
    /*border-radius: 50%;*/
}
.movie_description_buttons_user{
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*width: 30%;*/
    /*max-width: 50%;*/

}
.movie_description_buttons_user svg{
    font-size:16px;
    /*color:#9019be;*/
    /*border-radius: 50%;*/
    /*padding: 1px;*/
    margin:0 auto;

    box-sizing: border-box;
    display:block;
    width:80px;
    height:80px;
    padding: 4px;
    /*padding-top: 14px;*/
    /*padding-left: 8px;*/
    line-height: 20px;
    border: 6px solid #fff;
    border-radius: 50%;
    color:#f5f5f5;
    text-align:center;
    text-decoration:none;
    background-color: rgba(0,0,0,0.5);
    /*font-size:20px;*/
    font-weight:bold;
    transition: all 0.3s ease;
}
.movie_description_buttons_user svg:hover {
    /*color: #5e0f7e;*/
    /*background: #8714b4;*/
    /*box-shadow: 0 0 10px #8316ad, 0 0 40px #8714b4, 0 0 80px #9019be;*/
    /*transition-delay: 100ms;*/

    background-color: rgba(0,0,0,0.8);
    box-shadow: 0px 0px 10px rgba(255,255,100,1);
    text-shadow: 0px 0px 10px rgba(255,255,100,1);
}

.movie_description_buttons_vote{
    font-size: 10pt;
    position: relative;
    display: flex;
    flex-direction: column;
    float: left;
    bottom:20px;
}
.movie_description_buttons_vote svg{
    margin: auto 5px;
    border: #000000 solid 1px;
    padding: 5px;
    border-radius: 50%;
}