@import url(https://fonts.googleapis.com/css?family=Roboto:700,900);
@import url(https://fonts.googleapis.com/css2?family=Grape+Nuts&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #112;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{margin: 0;}
.app{
    background-color: #112;

}

.modal{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: fixed;
    z-index: 99999;
    top:23.5vh;
    left:23.5vw;
    padding: 0;
    padding-top: 0;
    background-color: rgba(129, 129, 129, 0.48);
}
.modal_content{
    background-color: #2b2b2b;
    width: 55vw;
    height: 45vh;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.nav{
    position: fixed;
    top:0;
    width: 100%;
    padding: 20px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    /*animation*/
    transition: ease-in all 0.5s;
  
}
.nav_black{
    background-color: #111;
}

.nav_logo{
    position: fixed;
    top : 2px;
    left: 20px;
    width:80px;
    object-fit: contain;
}
.nav_avatar{
    transition: ease-in all 0.5s;
}
.nav_avatar{
    width: 50px;
    height:54px;
}
.nav_profile{
    color: azure;
    transition: ease-in all 0.5s;
    position: fixed;
    top : 35px;
    right: 120px;
}
.profile_container{
    display: flex;
    position: fixed;
    right: 20px;
    width:-webkit-max-content;
    width:max-content;
    object-fit: contain;
    transition: ease-in all 0.5s;
}
.search_link{
    display: flex;
    position: fixed;
    right: 150px;
    width:30vw;
    object-fit: contain;
    transition: ease-in all 0.5s;
    color: whitesmoke;
}

.container{
    width: 100%;
    height: 100vh;
    display: flex;
	justify-content: center;
	align-items: center;
    background: #112;
}
.loader_container {
	width: 200px;
	height: 200px;
	border-radius: 100%;
	background: linear-gradient(165deg, rgb(82, 23, 69) 0%, #112 40%, rgb(80, 13, 74) 98%, rgb(10, 10, 10) 100%);
	position: relative;
}
.loader {
	
}

.loader:before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	border-radius: 100%;
	border-bottom: 0 solid #ffffff05;
	box-shadow: 
		0 -10px 10px 10px #5e0f7e inset,
		0 -5px 5px 10px #3e0c52 inset,
		0 -2px 5px #5e0f7e inset,
		0 -3px 2px #3e0c52 inset,
		0 2px 0px #5e0f7e,
		0 2px 3px #ffffff,
		0 5px 5px #5e0f7e,
		0 10px 10px #3e0c52,
		0 10px 20px 20px #5e0f7e;
	-webkit-filter: blur(3px);
	        filter: blur(3px);
	-webkit-animation: 2s rotate linear infinite;
	        animation: 2s rotate linear infinite;
}

@-webkit-keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg)
	}
}

@keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg)
	}
}


.load_component {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 16pt;
  }
  
  @-webkit-keyframes arrows {
    0%,
    100% {
      color: black;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    50% {
      color: #b61df3;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
  }
  
  @keyframes arrows {
    0%,
    100% {
      color: black;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    50% {
      color: #b61df3;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
  }
  
  .load_component span {
    --delay: 0s;
    -webkit-animation: arrows 1s var(--delay) infinite ease-in;
            animation: arrows 1s var(--delay) infinite ease-in;
  }
.row{
    width: 97%;
    margin-left: 20px;
    color: white;
}

.row_posters{
    position: relative;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px;
}

.row_posters::-webkit-scrollbar{
    display: none;
}

.arrow_btn{
    border-radius: 5px;
    width: 30px;
    margin:0 0;
    margin-bottom: 15px;
    background-color: #112;
    color: #818181;
    /*border:none;*/
    height: 4vh;
    position: relative;
    top: 14px;
}

.arrow_btn_large{
    border-radius: 5px;
    width: 30px;
    margin:0 5px;
    background-color: #112;
    color: #818181;
    /*border:none;*/
    height: 4vh;
    position: relative;
}

.arrow_btn:hover,.arrow_btn_large:hover{
    background-color: rgba(12, 1, 10, 0.62);
}

.arrow_right{
    right:-6px;
}

.arrow_left{
    left:-6px;
}

.row_movie{
    cursor: pointer;
    object-fit: contain;
    width: 100%;
    max-height: 150px;
    margin-right: 10px;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
}

.row_movie:hover{
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
}

.row_large{
    max-height: 300px;
}
.row_large:hover{
    -webkit-transform: scale(1.09);
            transform: scale(1.09);
}
.movie_description{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #1c0525;
    height:100%;
    width: 300px;
    border-radius: 20px;
    box-shadow: 0 0 .2rem rgb(216, 213, 213),
    0 0 .4rem rgb(187, 187, 187),
    0 0 .3rem #5e0f7e,
    0 0 0.3rem #5e0f7e,
    0 0 0.2rem #5e0f7e,
    inset 0 0 1rem #1c0525; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.movie_description h1{
    font-size: 11pt;
    margin-left: 20px;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.movie_description p{
    font-size: 10pt;
    width: 95%;
    height: 80%;
    max-height: 90px;
    overflow: hidden;
    position: relative;
    display: block;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
}
.movie_description_buttons{
    /*display: flex;*/
    /*position:relative;*/
    /*bottom: 0;*/
    /*right: 0;*/
    /*padding: 0;*/
    /*justify-content: space-between;*/
    /*margin: 5px;*/
    /*width:12vw;*/
    /*height: 20px;*/
    /*border-radius: 50%;*/
}
.movie_description_buttons_user{
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*width: 30%;*/
    /*max-width: 50%;*/

}
.movie_description_buttons_user svg{
    font-size:16px;
    /*color:#9019be;*/
    /*border-radius: 50%;*/
    /*padding: 1px;*/
    margin:0 auto;

    box-sizing: border-box;
    display:block;
    width:80px;
    height:80px;
    padding: 4px;
    /*padding-top: 14px;*/
    /*padding-left: 8px;*/
    line-height: 20px;
    border: 6px solid #fff;
    border-radius: 50%;
    color:#f5f5f5;
    text-align:center;
    text-decoration:none;
    background-color: rgba(0,0,0,0.5);
    /*font-size:20px;*/
    font-weight:bold;
    transition: all 0.3s ease;
}
.movie_description_buttons_user svg:hover {
    /*color: #5e0f7e;*/
    /*background: #8714b4;*/
    /*box-shadow: 0 0 10px #8316ad, 0 0 40px #8714b4, 0 0 80px #9019be;*/
    /*transition-delay: 100ms;*/

    background-color: rgba(0,0,0,0.8);
    box-shadow: 0px 0px 10px rgba(255,255,100,1);
    text-shadow: 0px 0px 10px rgba(255,255,100,1);
}

.movie_description_buttons_vote{
    font-size: 10pt;
    position: relative;
    display: flex;
    flex-direction: column;
    float: left;
    bottom:20px;
}
.movie_description_buttons_vote svg{
    margin: auto 5px;
    border: #000000 solid 1px;
    padding: 5px;
    border-radius: 50%;
}
.banner{
    color:white;
    object-fit: contain;
    height: 450px;
}
.banner_contents{
    margin-left: 30px;
    padding-top: 140px;
    height: 190px;
}
.banner_contents_pop{
    /*margin-top: 20vh;*/

    padding-top: 0px;
    height: 45vh;
    width: 100%;
    background-color: rgba(129, 129, 129, 0.37);
}
.banner_title{
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 0.3rem;
}

.banner_description{
    width: 45rem;
    line-height: 1.3;
    padding-top: 1rem;
    font-size: 0.8rem;
    max-width: 370px;
    height: 80px;
}

.banner_button{
    cursor:pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: rgba(51,51,51, 0.5);   
}
.banner_button:hover{
    color: black;
    background-color: white;
    transition: all 0.2s;
}
.banner--fadeBottom{
    position: relative;
    height: 7.5rem;
    background-image: linear-gradient(
    180deg,
    transparent,
    rgba(37,37,37,0.60),
    #112
    );
}
.main_page_container{
    width: 100%;
}

.movie_description_buttons_vote{
    position: absolute;
    top:-30vh;
    left:40vw;
}
.movie_description_buttons_user{
    position: absolute;
    top:-7vh;
}

.shadow-box{
    background-color: rgba(43, 43, 43, 0.67);
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    padding: 10px;
}

.shadow_title{
    color: #131313;

    letter-spacing: .15em;
    text-shadow: 1px -1px 0 #767676, -1px 2px 1px #737272, -2px 4px 1px #767474, -3px 6px 1px #787777, -4px 8px 1px #7b7a7a, -5px 10px 1px #7f7d7d, -6px 12px 1px #828181, -7px 14px 1px #868585, -8px 16px 1px #8b8a89, -9px 18px 1px #8f8e8d, -10px 20px 1px #949392, -11px 22px 1px #999897, -12px 24px 1px #9e9c9c;
    color: #131313;

}
.shadow_description{
    color: transparent;
    background: #666666;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-shadow: 0px 3px 3px rgba(255,255,255,0.5);

}

.horizontal-navigation {
    text-align: center;
    -webkit-animation: fadeIn 0.8s;
    animation: fadeIn 0.8s;
}
.horizontal-navigation li {
    display: inline-block;
    margin-right: 30px;
}



.scroll-nav {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* hide the scrollbar if on webkit desktop device, this is optional */
::-webkit-scrollbar {
    display: none;
}

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.container{
    display: flex;
    flex-direction: column;
    width: 60vm;
    color: whitesmoke;
    justify-items: center;
    align-items: center;
    
}
#form{
    width: 60%;
    color: grey;
    font-size: 14pt;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form_row{
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 30px auto;
    padding: 5px;
    width: 100%;
}
.detail{
    width: 70%;
    
}
.danger{
    background-color: red;
    padding: 0 20px;
    font-size: 14pt;
}
.form_row input{
    width: 100%;
    height: 50px;
   border-radius: 10px;
   transition: ease-in 0.3s;
   text-align: center;
   font-size: 16pt;
   
}
.form_row button{
    width: 70%;
    margin: 10px auto;
    height: 45px;
    color: white;
    font-style:italic;
    font-size: 14pt;
    font-weight: 600;
    background-color: rgb(73, 27, 116);
    padding: 5px;
    text-align: center;
    border-radius: 10px;
    transition: ease-in-out 0.2s;
}
.form_row button:hover{
    background-color: blueviolet;
}
.form_row input:focus{
    outline:none;
    border-bottom: 5px solid blueviolet;
    color: black;
    height: 60px;
    border-radius: 15px;

  }
  
.room-container{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: rgb(12, 1, 10);
    justify-content: center;
     align-items: center;
}
.video-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    padding: 1rem;
}
#videoPlayer{
    position: relative;
    width: 100%;
    transition: ease-in-out 0.5;
    
}
#videoPlayer:focus{
}
.viewer-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    position: fixed;
    bottom: -60px;
}
.cinema-row{
    display: flex;
    justify-content: center;
}
.seat {
    position: relative;
    cursor: pointer;
        width: 250px;
        height: 80px;
        border-radius: 30px 30px 0px 0px;
        background: linear-gradient(to top, #76181800, #7618182a, #76181850, #76181863, #76181896, #b54042ad,  #F3686A);
        margin-bottom: 15px;
        border-left: solid rgb(3, 0, 3) 5px;
        margin-left: -3px;
        -webkit-transform: skew(10deg);
                transform: skew(10deg);
        margin-top: -22px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        z-index: 1;
        
}

  .row-2{
      margin-left: 50px;
  }
  .row-2 .seat{
    width: 300px;
    height: 50px;
    margin-left: -8px;
    border-left: solid rgb(3, 0, 3) 7px;
    border-radius: 30px;
    background: linear-gradient(to top,#76181800, #76181838, #76181838, #7618185b, #b54042,  #f3686a);
  
  }
  .row-2 .seat:hover:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 7px;
  }
  .seat svg{
      position: absolute;
      bottom: -50px;
      width: 200px;
      right: 0;
      height: 200px;
      z-index: 0;
      opacity: 1;
      -webkit-filter: grayscale(100%) contrast(0%) brightness(0%);
              filter: grayscale(100%) contrast(0%) brightness(0%);
  }


  /*@media screen and(min-width: 780px){*/
  /*    .viewer-container {*/
  /*        display: none;*/
  /*    }*/
  /*}*/

.profile_page_container{
    
    width: 100%;
    height: 100vh;


    justify-content: center;
    align-items: center;
}
.profile_info_display{
    display: flex;
    align-items: flex-start;
    width: 50%;
    position: relative;
    left: 5px;
    top:100px;
    transition: 200ms ease-in-out;
}
.profile_info_display:hover{
    box-shadow: 0 0 .2rem rgb(216, 213, 213),
    0 0 .4rem rgb(187, 187, 187),
    0 0 .3rem #5e0f7e,
    0 0 0.3rem #5e0f7e,
    0 0 0.2rem #5e0f7e,
    inset 0 0 1rem #5e0f7e; 
    width: 51%;
}
.profile_from{
   
    width: 100%;
    font-size: 14pt;
    display: flex;
    align-items: flex-start;
}
.profile_from_row{
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 30px auto;
    padding: 5px;
    width: 100%;
}
.profile_from input{
    background: none;
    border: none;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    transition: ease-in 0.3s;
    margin-left: 20px;
    font-size: 16pt;
    color:whitesmoke;
}
.profile_avatar{
    width: 200px;
    height: 200px;
    justify-self: center;
    align-items: center;
    margin: 0 auto;
}
.user_profile_avatar svg{
    width: 200px;
    height: 200px;
    justify-self: center;
    align-items: center;
    margin: 0 auto;
     transition: 6ms;
     
}
.select_avatar_row{
    position: absolute;
    display: flex;
    
    right: 0;
    bottom: 0;
   border-radius: 10px;
    background-color: #460b5e;
    z-index: 2;
    transition: ease-in-out 0.2s;
}
.select_avatar_row svg{
    width: 50px;
    height: 60px;

}
.body {
    background: radial-gradient(#fff, #aaa);
    background-size: 100vw 100vh;
    overflow: hidden;
    padding-top: 40vh;
    position: absolute;
    top: 0;
    right: 0;
    left:0;
    bottom: 0;
    z-index:999999999;
}

.txt {
    text-align: center;
    font-family: 'Grape Nuts', cursive;
    font-size: 12vmin;
    font-weight: 700;
    -webkit-animation: netflix_style 3.5s infinite;
            animation: netflix_style 3.5s infinite;
    outline: none;
    white-space: nowrap;
}

@-webkit-keyframes netflix_style {
    0% {
        text-shadow: 0px 0px transparent, 1px 1px #aaa, 2px 2px #aaa, 3px 3px #aaa, 4px 4px #aaa, 5px 5px #aaa, 6px 6px #aaa, 7px 7px #aaa, 8px 8px #aaa, 9px 9px #aaa, 10px 10px #aaa, 11px 11px #aaa, 12px 12px #aaa, 13px 13px #aaa, 14px 14px #aaa, 15px 15px #aaa, 16px 16px #aaa, 17px 17px #aaa, 18px 18px #aaa, 19px 19px #aaa, 20px 20px #aaa, 21px 21px #aaa, 22px 22px #aaa, 23px 23px #aaa, 24px 24px #aaa, 25px 25px #aaa, 26px 26px #aaa, 27px 27px #aaa, 28px 28px #aaa, 29px 29px #aaa, 30px 30px #aaa, 31px 31px #aaa, 32px 32px #aaa, 33px 33px #aaa, 34px 34px #aaa, 35px 35px #aaa, 36px 36px #aaa, 37px 37px #aaa, 38px 38px #aaa, 39px 39px #aaa, 40px 40px #aaa, 41px 41px #aaa, 42px 42px #aaa, 43px 43px #aaa, 44px 44px #aaa, 45px 45px #aaa, 46px 46px #aaa, 47px 47px #aaa, 48px 48px #aaa, 49px 49px #aaa, 50px 50px #aaa, 51px 51px #aaa, 52px 52px #aaa, 53px 53px #aaa, 54px 54px #aaa, 55px 55px #aaa, 56px 56px #aaa, 57px 57px #aaa, 58px 58px #aaa, 59px 59px #aaa, 60px 60px #aaa, 61px 61px #aaa, 62px 62px #aaa, 63px 63px #aaa, 64px 64px #aaa, 65px 65px #aaa, 66px 66px #aaa, 67px 67px #aaa, 68px 68px #aaa, 69px 69px #aaa, 70px 70px #aaa, 71px 71px #aaa, 72px 72px #aaa, 73px 73px #aaa, 74px 74px #aaa, 75px 75px #aaa, 76px 76px #aaa, 77px 77px #aaa, 78px 78px #aaa, 79px 79px #aaa, 80px 80px #aaa, 81px 81px #aaa, 82px 82px #aaa, 83px 83px #aaa, 84px 84px #aaa, 85px 85px #aaa, 86px 86px #aaa, 87px 87px #aaa, 88px 88px #aaa, 89px 89px #aaa, 90px 90px #aaa, 91px 91px #aaa, 92px 92px #aaa, 93px 93px #aaa, 94px 94px #aaa, 95px 95px #aaa, 96px 96px #aaa, 97px 97px #aaa, 98px 98px #aaa, 99px 99px #aaa, 100px 100px #aaa;
        color: #f3f3f3;
        -webkit-transform: scale(1.5, 1.5);
                transform: scale(1.5, 1.5);
    }
    10% {
        text-shadow: 0px 0px transparent, 1px 1.5px #aaa, 2px 3px #aaa, 3px 4.5px #aaa, 4px 6px #aaa, 5px 7.5px #aaa, 6px 9px #aaa, 7px 10.5px #aaa, 8px 12px #aaa, 9px 13.5px #aaa, 10px 15px #aaa, 11px 16.5px #aaa, 12px 18px #aaa, 13px 19.5px #aaa, 14px 21px #aaa, 15px 22.5px #aaa, 16px 24px #aaa, 17px 25.5px #aaa, 18px 27px #aaa, 19px 28.5px #aaa, 20px 30px #aaa, 21px 31.5px #aaa, 22px 33px #aaa, 23px 34.5px #aaa, 24px 36px #aaa, 25px 37.5px #aaa, 26px 39px #aaa, 27px 40.5px #aaa, 28px 42px #aaa, 29px 43.5px #aaa, 30px 45px #aaa, 31px 46.5px #aaa, 32px 48px #aaa, 33px 49.5px #aaa, 34px 51px #aaa, 35px 52.5px #aaa, 36px 54px #aaa, 37px 55.5px #aaa, 38px 57px #aaa, 39px 58.5px #aaa, 40px 60px #aaa, 41px 61.5px #aaa, 42px 63px #aaa, 43px 64.5px #aaa, 44px 66px #aaa, 45px 67.5px #aaa, 46px 69px #aaa, 47px 70.5px #aaa, 48px 72px #aaa, 49px 73.5px #aaa, 50px 75px #aaa, 51px 76.5px #aaa, 52px 78px #aaa, 53px 79.5px #aaa, 54px 81px #aaa, 55px 82.5px #aaa, 56px 84px #aaa, 57px 85.5px #aaa, 58px 87px #aaa, 59px 88.5px #aaa, 60px 90px #aaa, 61px 91.5px #aaa, 62px 93px #aaa, 63px 94.5px #aaa, 64px 96px #aaa, 65px 97.5px #aaa, 66px 99px #aaa, 67px 100.5px #aaa, 68px 102px #aaa, 69px 103.5px #aaa, 70px 105px #aaa, 71px 106.5px #aaa, 72px 108px #aaa, 73px 109.5px #aaa, 74px 111px #aaa, 75px 112.5px #aaa, 76px 114px #aaa, 77px 115.5px #aaa, 78px 117px #aaa, 79px 118.5px #aaa, 80px 120px #aaa, 81px 121.5px #aaa, 82px 123px #aaa, 83px 124.5px #aaa, 84px 126px #aaa, 85px 127.5px #aaa, 86px 129px #aaa, 87px 130.5px #aaa, 88px 132px #aaa, 89px 133.5px #aaa, 90px 135px #aaa, 91px 136.5px #aaa, 92px 138px #aaa, 93px 139.5px #aaa, 94px 141px #aaa, 95px 142.5px #aaa, 96px 144px #aaa, 97px 145.5px #aaa, 98px 147px #aaa, 99px 148.5px #aaa, 100px 150px #aaa;
        color: #f3f3f3;
        -webkit-transform: scale(1.5, 1.5);
                transform: scale(1.5, 1.5);
    }
    15% {
        color: #f3f3f3;
    }
    20% {
        color: #e90418;
        text-shadow: none;
        -webkit-transform: scale(1.1, 1.1);
                transform: scale(1.1, 1.1);
    }
    75% {
        opacity: 1;
    }
    80% {
        opacity: 0;
        color: #e90418;
        -webkit-transform: scale(0.85, 0.9);
                transform: scale(0.85, 0.9);
    }
    100% {
        opacity: 0;
    }
}

@keyframes netflix_style {
    0% {
        text-shadow: 0px 0px transparent, 1px 1px #aaa, 2px 2px #aaa, 3px 3px #aaa, 4px 4px #aaa, 5px 5px #aaa, 6px 6px #aaa, 7px 7px #aaa, 8px 8px #aaa, 9px 9px #aaa, 10px 10px #aaa, 11px 11px #aaa, 12px 12px #aaa, 13px 13px #aaa, 14px 14px #aaa, 15px 15px #aaa, 16px 16px #aaa, 17px 17px #aaa, 18px 18px #aaa, 19px 19px #aaa, 20px 20px #aaa, 21px 21px #aaa, 22px 22px #aaa, 23px 23px #aaa, 24px 24px #aaa, 25px 25px #aaa, 26px 26px #aaa, 27px 27px #aaa, 28px 28px #aaa, 29px 29px #aaa, 30px 30px #aaa, 31px 31px #aaa, 32px 32px #aaa, 33px 33px #aaa, 34px 34px #aaa, 35px 35px #aaa, 36px 36px #aaa, 37px 37px #aaa, 38px 38px #aaa, 39px 39px #aaa, 40px 40px #aaa, 41px 41px #aaa, 42px 42px #aaa, 43px 43px #aaa, 44px 44px #aaa, 45px 45px #aaa, 46px 46px #aaa, 47px 47px #aaa, 48px 48px #aaa, 49px 49px #aaa, 50px 50px #aaa, 51px 51px #aaa, 52px 52px #aaa, 53px 53px #aaa, 54px 54px #aaa, 55px 55px #aaa, 56px 56px #aaa, 57px 57px #aaa, 58px 58px #aaa, 59px 59px #aaa, 60px 60px #aaa, 61px 61px #aaa, 62px 62px #aaa, 63px 63px #aaa, 64px 64px #aaa, 65px 65px #aaa, 66px 66px #aaa, 67px 67px #aaa, 68px 68px #aaa, 69px 69px #aaa, 70px 70px #aaa, 71px 71px #aaa, 72px 72px #aaa, 73px 73px #aaa, 74px 74px #aaa, 75px 75px #aaa, 76px 76px #aaa, 77px 77px #aaa, 78px 78px #aaa, 79px 79px #aaa, 80px 80px #aaa, 81px 81px #aaa, 82px 82px #aaa, 83px 83px #aaa, 84px 84px #aaa, 85px 85px #aaa, 86px 86px #aaa, 87px 87px #aaa, 88px 88px #aaa, 89px 89px #aaa, 90px 90px #aaa, 91px 91px #aaa, 92px 92px #aaa, 93px 93px #aaa, 94px 94px #aaa, 95px 95px #aaa, 96px 96px #aaa, 97px 97px #aaa, 98px 98px #aaa, 99px 99px #aaa, 100px 100px #aaa;
        color: #f3f3f3;
        -webkit-transform: scale(1.5, 1.5);
                transform: scale(1.5, 1.5);
    }
    10% {
        text-shadow: 0px 0px transparent, 1px 1.5px #aaa, 2px 3px #aaa, 3px 4.5px #aaa, 4px 6px #aaa, 5px 7.5px #aaa, 6px 9px #aaa, 7px 10.5px #aaa, 8px 12px #aaa, 9px 13.5px #aaa, 10px 15px #aaa, 11px 16.5px #aaa, 12px 18px #aaa, 13px 19.5px #aaa, 14px 21px #aaa, 15px 22.5px #aaa, 16px 24px #aaa, 17px 25.5px #aaa, 18px 27px #aaa, 19px 28.5px #aaa, 20px 30px #aaa, 21px 31.5px #aaa, 22px 33px #aaa, 23px 34.5px #aaa, 24px 36px #aaa, 25px 37.5px #aaa, 26px 39px #aaa, 27px 40.5px #aaa, 28px 42px #aaa, 29px 43.5px #aaa, 30px 45px #aaa, 31px 46.5px #aaa, 32px 48px #aaa, 33px 49.5px #aaa, 34px 51px #aaa, 35px 52.5px #aaa, 36px 54px #aaa, 37px 55.5px #aaa, 38px 57px #aaa, 39px 58.5px #aaa, 40px 60px #aaa, 41px 61.5px #aaa, 42px 63px #aaa, 43px 64.5px #aaa, 44px 66px #aaa, 45px 67.5px #aaa, 46px 69px #aaa, 47px 70.5px #aaa, 48px 72px #aaa, 49px 73.5px #aaa, 50px 75px #aaa, 51px 76.5px #aaa, 52px 78px #aaa, 53px 79.5px #aaa, 54px 81px #aaa, 55px 82.5px #aaa, 56px 84px #aaa, 57px 85.5px #aaa, 58px 87px #aaa, 59px 88.5px #aaa, 60px 90px #aaa, 61px 91.5px #aaa, 62px 93px #aaa, 63px 94.5px #aaa, 64px 96px #aaa, 65px 97.5px #aaa, 66px 99px #aaa, 67px 100.5px #aaa, 68px 102px #aaa, 69px 103.5px #aaa, 70px 105px #aaa, 71px 106.5px #aaa, 72px 108px #aaa, 73px 109.5px #aaa, 74px 111px #aaa, 75px 112.5px #aaa, 76px 114px #aaa, 77px 115.5px #aaa, 78px 117px #aaa, 79px 118.5px #aaa, 80px 120px #aaa, 81px 121.5px #aaa, 82px 123px #aaa, 83px 124.5px #aaa, 84px 126px #aaa, 85px 127.5px #aaa, 86px 129px #aaa, 87px 130.5px #aaa, 88px 132px #aaa, 89px 133.5px #aaa, 90px 135px #aaa, 91px 136.5px #aaa, 92px 138px #aaa, 93px 139.5px #aaa, 94px 141px #aaa, 95px 142.5px #aaa, 96px 144px #aaa, 97px 145.5px #aaa, 98px 147px #aaa, 99px 148.5px #aaa, 100px 150px #aaa;
        color: #f3f3f3;
        -webkit-transform: scale(1.5, 1.5);
                transform: scale(1.5, 1.5);
    }
    15% {
        color: #f3f3f3;
    }
    20% {
        color: #e90418;
        text-shadow: none;
        -webkit-transform: scale(1.1, 1.1);
                transform: scale(1.1, 1.1);
    }
    75% {
        opacity: 1;
    }
    80% {
        opacity: 0;
        color: #e90418;
        -webkit-transform: scale(0.85, 0.9);
                transform: scale(0.85, 0.9);
    }
    100% {
        opacity: 0;
    }
}
.App-search{
 color: whitesmoke;
}
.youtube{
    width: 100vw;
    height: 70vh;
}
.center-max-size {

    max-width: 1020px;
    margin: 0 auto;
    margin-top: 10vh;
    padding: 0 15px;
}

.header-search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    text-transform: uppercase;
}

.poster {
    background-size: cover;
    background-position: top center;
    min-height: 600px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 50px;
    position: relative;
}

.poster-content {
    width: 80%;
}

h1 {
    font-size: 92px;
    margin-top: 0;
    line-height: 1;
    margin-bottom: 10px;
}

.container-search {
    background-color: #0F1014;
    padding-top: 80px;
    padding-bottom: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    gap: 30px;
}


.button {
    margin: 20px 0;
    background: #0F1014;
    color: white;
    border: none;
    outline: none;
    padding: 10px 15px;
    cursor: pointer;
    transition: box-shadow 0.2s;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 1px solid gray;
}

.button:hover {
    box-shadow: 0 5px 5px rgba(0,0,0,0.5);
}

.close-video {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    left: 10px;
}

.youtube-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.brand {
    font-weight: bold;
}

/*-- MOVIE */

.movie {
    position: relative;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
}

.movie img {
    height: 355px;
    max-width: 100%;
}

.movie-infos {
    padding: 15px 0;
}

.movie-title {
    margin: 0;
}

.movie-voting {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: #0F1014;
    border: 1px solid gray;
    padding: 10px;
    font-size: 12px;
    border-radius: 25px;
}

/** SEARCH **/
.form {
    position: relative;
}

.search {
    border: 1px solid darkslategray;
    outline: transparent;
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 25px;
    color: white;

}

.submit-search {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: transparent;
    color:  white;
    border: none;
    cursor: pointer;
}
