.room-container{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: rgb(12, 1, 10);
    justify-content: center;
     align-items: center;
}
.video-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    padding: 1rem;
}
#videoPlayer{
    position: relative;
    width: 100%;
    transition: ease-in-out 0.5;
    
}
#videoPlayer:focus{
}
.viewer-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    position: fixed;
    bottom: -60px;
}
.cinema-row{
    display: flex;
    justify-content: center;
}
.seat {
    position: relative;
    cursor: pointer;
        width: 250px;
        height: 80px;
        border-radius: 30px 30px 0px 0px;
        background: linear-gradient(to top, #76181800, #7618182a, #76181850, #76181863, #76181896, #b54042ad,  #F3686A);
        margin-bottom: 15px;
        border-left: solid rgb(3, 0, 3) 5px;
        margin-left: -3px;
        transform: skew(10deg);
        margin-top: -22px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        z-index: 1;
        
}

  .row-2{
      margin-left: 50px;
  }
  .row-2 .seat{
    width: 300px;
    height: 50px;
    margin-left: -8px;
    border-left: solid rgb(3, 0, 3) 7px;
    border-radius: 30px;
    background: linear-gradient(to top,#76181800, #76181838, #76181838, #7618185b, #b54042,  #f3686a);
  
  }
  .row-2 .seat:hover:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 7px;
  }
  .seat svg{
      position: absolute;
      bottom: -50px;
      width: 200px;
      right: 0;
      height: 200px;
      z-index: 0;
      opacity: 1;
      filter: grayscale(100%) contrast(0%) brightness(0%);
  }


  /*@media screen and(min-width: 780px){*/
  /*    .viewer-container {*/
  /*        display: none;*/
  /*    }*/
  /*}*/