
.nav{
    position: fixed;
    top:0;
    width: 100%;
    padding: 20px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    /*animation*/
    transition: ease-in all 0.5s;
  
}
.nav_black{
    background-color: #111;
}

.nav_logo{
    position: fixed;
    top : 2px;
    left: 20px;
    width:80px;
    object-fit: contain;
}
.nav_avatar{
    transition: ease-in all 0.5s;
}
.nav_avatar{
    width: 50px;
    height:54px;
}
.nav_profile{
    color: azure;
    transition: ease-in all 0.5s;
    position: fixed;
    top : 35px;
    right: 120px;
}
.profile_container{
    display: flex;
    position: fixed;
    right: 20px;
    width:max-content;
    object-fit: contain;
    transition: ease-in all 0.5s;
}
.search_link{
    display: flex;
    position: fixed;
    right: 150px;
    width:30vw;
    object-fit: contain;
    transition: ease-in all 0.5s;
    color: whitesmoke;
}
