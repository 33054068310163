.container{
    display: flex;
    flex-direction: column;
    width: 60vm;
    color: whitesmoke;
    justify-items: center;
    align-items: center;
    
}
#form{
    width: 60%;
    color: grey;
    font-size: 14pt;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form_row{
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 30px auto;
    padding: 5px;
    width: 100%;
}
.detail{
    width: 70%;
    
}
.danger{
    background-color: red;
    padding: 0 20px;
    font-size: 14pt;
}
.form_row input{
    width: 100%;
    height: 50px;
   border-radius: 10px;
   transition: ease-in 0.3s;
   text-align: center;
   font-size: 16pt;
   
}
.form_row button{
    width: 70%;
    margin: 10px auto;
    height: 45px;
    color: white;
    font-style:italic;
    font-size: 14pt;
    font-weight: 600;
    background-color: rgb(73, 27, 116);
    padding: 5px;
    text-align: center;
    border-radius: 10px;
    transition: ease-in-out 0.2s;
}
.form_row button:hover{
    background-color: blueviolet;
}
.form_row input:focus{
    outline:none;
    border-bottom: 5px solid blueviolet;
    color: black;
    height: 60px;
    border-radius: 15px;

  }
  