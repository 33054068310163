
.profile_page_container{
    
    width: 100%;
    height: 100vh;


    justify-content: center;
    align-items: center;
}
.profile_info_display{
    display: flex;
    align-items: flex-start;
    width: 50%;
    position: relative;
    left: 5px;
    top:100px;
    transition: 200ms ease-in-out;
}
.profile_info_display:hover{
    box-shadow: 0 0 .2rem rgb(216, 213, 213),
    0 0 .4rem rgb(187, 187, 187),
    0 0 .3rem #5e0f7e,
    0 0 0.3rem #5e0f7e,
    0 0 0.2rem #5e0f7e,
    inset 0 0 1rem #5e0f7e; 
    width: 51%;
}
.profile_from{
   
    width: 100%;
    font-size: 14pt;
    display: flex;
    align-items: flex-start;
}
.profile_from_row{
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 30px auto;
    padding: 5px;
    width: 100%;
}
.profile_from input{
    background: none;
    border: none;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    transition: ease-in 0.3s;
    margin-left: 20px;
    font-size: 16pt;
    color:whitesmoke;
}
.profile_avatar{
    width: 200px;
    height: 200px;
    justify-self: center;
    align-items: center;
    margin: 0 auto;
}
.user_profile_avatar svg{
    width: 200px;
    height: 200px;
    justify-self: center;
    align-items: center;
    margin: 0 auto;
     transition: 6ms;
     
}
.select_avatar_row{
    position: absolute;
    display: flex;
    
    right: 0;
    bottom: 0;
   border-radius: 10px;
    background-color: #460b5e;
    z-index: 2;
    transition: ease-in-out 0.2s;
}
.select_avatar_row svg{
    width: 50px;
    height: 60px;

}