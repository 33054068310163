.main_page_container{
    width: 100%;
}

.movie_description_buttons_vote{
    position: absolute;
    top:-30vh;
    left:40vw;
}
.movie_description_buttons_user{
    position: absolute;
    top:-7vh;
}

.shadow-box{
    background-color: rgba(43, 43, 43, 0.67);
    max-width: fit-content;
    max-height: fit-content;
    padding: 10px;
}

.shadow_title{
    color: #131313;

    letter-spacing: .15em;
    text-shadow: 1px -1px 0 #767676, -1px 2px 1px #737272, -2px 4px 1px #767474, -3px 6px 1px #787777, -4px 8px 1px #7b7a7a, -5px 10px 1px #7f7d7d, -6px 12px 1px #828181, -7px 14px 1px #868585, -8px 16px 1px #8b8a89, -9px 18px 1px #8f8e8d, -10px 20px 1px #949392, -11px 22px 1px #999897, -12px 24px 1px #9e9c9c;
    color: #131313;

}
.shadow_description{
    color: transparent;
    background: #666666;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-shadow: 0px 3px 3px rgba(255,255,255,0.5);

}

.horizontal-navigation {
    text-align: center;
    -webkit-animation: fadeIn 0.8s;
    animation: fadeIn 0.8s;
}
.horizontal-navigation li {
    display: inline-block;
    margin-right: 30px;
}



.scroll-nav {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* hide the scrollbar if on webkit desktop device, this is optional */
::-webkit-scrollbar {
    display: none;
}

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}