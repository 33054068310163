*{margin: 0;}
.app{
    background-color: #112;

}

.modal{
    width:fit-content;
    height: fit-content;
    position: fixed;
    z-index: 99999;
    top:23.5vh;
    left:23.5vw;
    padding: 0;
    padding-top: 0;
    background-color: rgba(129, 129, 129, 0.48);
}
.modal_content{
    background-color: #2b2b2b;
    width: 55vw;
    height: 45vh;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}