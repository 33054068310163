.container{
    width: 100%;
    height: 100vh;
    display: flex;
	justify-content: center;
	align-items: center;
    background: #112;
}
.loader_container {
	width: 200px;
	height: 200px;
	border-radius: 100%;
	background: linear-gradient(165deg, rgb(82, 23, 69) 0%, #112 40%, rgb(80, 13, 74) 98%, rgb(10, 10, 10) 100%);
	position: relative;
}
.loader {
	
}

.loader:before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	border-radius: 100%;
	border-bottom: 0 solid #ffffff05;
	box-shadow: 
		0 -10px 10px 10px #5e0f7e inset,
		0 -5px 5px 10px #3e0c52 inset,
		0 -2px 5px #5e0f7e inset,
		0 -3px 2px #3e0c52 inset,
		0 2px 0px #5e0f7e,
		0 2px 3px #ffffff,
		0 5px 5px #5e0f7e,
		0 10px 10px #3e0c52,
		0 10px 20px 20px #5e0f7e;
	filter: blur(3px);
	animation: 2s rotate linear infinite;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg)
	}
}


.load_component {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 16pt;
  }
  
  @keyframes arrows {
    0%,
    100% {
      color: black;
      transform: translateY(0);
    }
    50% {
      color: #b61df3;
      transform: translateY(20px);
    }
  }
  
  .load_component span {
    --delay: 0s;
    animation: arrows 1s var(--delay) infinite ease-in;
  }